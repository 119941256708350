































import { ReadingMaterialFileType } from '@/utils/constants';
import { ToastProgrammatic } from 'buefy';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class ReadingMaterialUpload extends Vue {
  currentFile: File | null = null;
  isFileSizeValid = true;
  isFileTypeValid = true;
  previousFileUrl = '';

  getImageUrl() {
    let imageSrc = ``;
    const splits = this.currentFile!.name.split('.');
    const fileExtension = splits[splits.length - 1];
    switch (fileExtension) {
      case ReadingMaterialFileType.pptx: {
        imageSrc = `powerpoint.svg`;
        break;
      }
      case ReadingMaterialFileType.xlsx: {
        imageSrc = `ic_excel.svg`;
        break;
      }
      case ReadingMaterialFileType.doc: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.docx: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.pdf: {
        imageSrc = `ic_pdf.svg`;
        break;
      }
      default: {
        imageSrc = `ic_img.svg`;
        break;
      }
    }

    return require(`@/assets/icons/${imageSrc}`);
  }

  get currentFileName() {
    return this.currentFile ? this.currentFile.name : '';
  }

  @Prop({ required: true })
  fileTypes!: string[];

  @Prop()
  previousSelectedFile!: string;

  @Watch(`previousSelectedFile`)
  updateCurrentFile() {
    if (this.previousSelectedFile.length > 0) {
      this.previousFileUrl = this.previousSelectedFile;
    }
  }

  @Prop({ default: `Invalid File Type. Expecting Microsoft Word, Microsoft Excel, Microsoft Powerpoint, PDF, JPEG or PNG `})
  invalidFileMessage!: string;

  checkAttachment(data: File) {
    this.isFileSizeValid = true;
    this.isFileTypeValid = true;

    if (!this.fileTypes.includes(data.type)) {
      ToastProgrammatic.open({
        type: 'is-danger',
        duration: 3000,
        message: this.invalidFileMessage,
      });
      this.isFileTypeValid = false;
      this.currentFile = null;
    }

    if (this.isFileTypeValid && this.isFileSizeValid) {
      this.updateAttachment(data);
    }
  }

  @Emit(`input`)
  updateAttachment(data: File) {
    this.currentFile = data;
    return this.currentFile;
  }
}
